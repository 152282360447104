import React, { Component } from "react";
import { Button, Grid, Header, Image, Modal } from "semantic-ui-react";
import { connect } from "react-redux";
import { rejectIfAnyNull } from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import { formatQuery } from "../controllers/utilities/string-utils";
import { getAdItem, setAdItem, getAdItemByEventId, ImgVideo, ImgVideoSmall } from "../components/lib/ad-manage-api-lib";
import KaisakuUserApi from "../controllers/kaisaku-user-api";
import { navigate } from "../../.cache/gatsby-browser-entry";
import { getUA } from "react-device-detect";
import { AdAregionSwipetoslide } from "../components/react-slick/ad-aregion-swipetoslide";
import { AdBcdregionSwipetoslide } from "../components/react-slick/ad-bcdregion-swipetoslide";
import "../styles/bg.css";
import { getFullPath } from "../controllers/kaisaku-api";
import Lambda from "../controllers/utilities/aws-lambda";
import KaisakuApi from "../controllers/kaisaku-api";

import noodle1 from "../images/noodles/8/Artboard-noodle1-fr-1.jpg";
import noodle2 from "../images/noodles/8/Artboard-noodle1-fr-1.jpg";

import login01_p from "../images/noodles/4/portrait/1.jpg";
import login02_p from "../images/noodles/4/portrait/2.jpg";
import login03_p from "../images/noodles/4/portrait/3.jpg";
import login04_p from "../images/noodles/4/portrait/4.jpg";
import login05_p from "../images/noodles/4/portrait/5.jpg";
import login06_p from "../images/noodles/4/portrait/6.jpg";
import login07_p from "../images/noodles/4/portrait/7.jpg";
import login08_p from "../images/noodles/4/portrait/8.jpg";
import login09_p from "../images/noodles/4/portrait/9.jpg";
import login10_p from "../images/noodles/4/portrait/10.jpg";
import login11_p from "../images/noodles/4/portrait/11.jpg";
import login12_p from "../images/noodles/4/portrait/12.jpg";
import login13_p from "../images/noodles/4/portrait/13.jpg";
import login14_p from "../images/noodles/4/portrait/14.jpg";
import login15_p from "../images/noodles/4/portrait/15.jpg";
import login16_p from "../images/noodles/4/portrait/16.jpg";
import login17_p from "../images/noodles/4/portrait/17.jpg";
import login18_p from "../images/noodles/4/portrait/18.jpg";
import login19_p from "../images/noodles/4/portrait/19.jpg";
import login20_p from "../images/noodles/4/portrait/20.jpg";
import login21_p from "../images/noodles/4/portrait/21.jpg";
import login22_p from "../images/noodles/4/portrait/22.jpg";
import login23_p from "../images/noodles/4/portrait/23.jpg";
import login24_p from "../images/noodles/4/portrait/24.jpg";
import login25_p from "../images/noodles/4/portrait/25.jpg";
import login26_p from "../images/noodles/4/portrait/26.jpg";
import login27_p from "../images/noodles/4/portrait/27.jpg";
import login28_p from "../images/noodles/4/portrait/28.jpg";
import login29_p from "../images/noodles/4/portrait/29.jpg";
import login30_p from "../images/noodles/4/portrait/30.jpg";
import login31_p from "../images/noodles/4/portrait/31.jpg";

import login01_l from "../images/noodles/4/landscape/1.jpg";
import login02_l from "../images/noodles/4/landscape/2.jpg";
import login03_l from "../images/noodles/4/landscape/3.jpg";
import login04_l from "../images/noodles/4/landscape/4.jpg";
import login05_l from "../images/noodles/4/landscape/5.jpg";
import login06_l from "../images/noodles/4/landscape/6.jpg";
import login07_l from "../images/noodles/4/landscape/7.jpg";
import login08_l from "../images/noodles/4/landscape/8.jpg";
import login09_l from "../images/noodles/4/landscape/9.jpg";
import login10_l from "../images/noodles/4/landscape/10.jpg";
import login11_l from "../images/noodles/4/landscape/11.jpg";
import login12_l from "../images/noodles/4/landscape/12.jpg";
import login13_l from "../images/noodles/4/landscape/13.jpg";
import login14_l from "../images/noodles/4/landscape/14.jpg";
import login15_l from "../images/noodles/4/landscape/15.jpg";
import login16_l from "../images/noodles/4/landscape/16.jpg";
import login17_l from "../images/noodles/4/landscape/17.jpg";
import login18_l from "../images/noodles/4/landscape/18.jpg";
import login19_l from "../images/noodles/4/landscape/19.jpg";
import login20_l from "../images/noodles/4/landscape/20.jpg";
import login21_l from "../images/noodles/4/landscape/21.jpg";
import login22_l from "../images/noodles/4/landscape/22.jpg";
import login23_l from "../images/noodles/4/landscape/23.jpg";
import login24_l from "../images/noodles/4/landscape/24.jpg";
import login25_l from "../images/noodles/4/landscape/25.jpg";
import login26_l from "../images/noodles/4/landscape/26.jpg";
import login27_l from "../images/noodles/4/landscape/27.jpg";
import login28_l from "../images/noodles/4/landscape/28.jpg";
import login29_l from "../images/noodles/4/landscape/29.jpg";
import login30_l from "../images/noodles/4/landscape/30.jpg";
import login31_l from "../images/noodles/4/landscape/31.jpg";

import a3_noodle1 from "../images/noodles/3/Artboard-noodle1-nw-1.jpg";
import a3_noodle1_2 from "../images/noodles/3/Artboard-noodle1-nw-2.jpg";
import a3_noodle1_3 from "../images/noodles/3/Artboard-noodle1-nw-3.jpg";
import a3_noodle1_4 from "../images/noodles/3/Artboard-noodle1-nw-4.jpg";
import a3_noodle1_5 from "../images/noodles/3/Artboard-noodle1-nw-5.jpg";
import a3_noodle1_6 from "../images/noodles/3/Artboard-noodle1-nw-6.jpg";
import a3_noodle1_7 from "../images/noodles/3/Artboard-noodle1-nw-7.jpg";

import a4_noodle1 from "../images/noodles/4/Artboard-noodle1-ml-1.jpg";
import a4_noodle1_2 from "../images/noodles/4/Artboard-noodle1-ml-2.jpg";
import a4_noodle1_3 from "../images/noodles/4/Artboard-noodle1-ml-3.jpg";
import a4_noodle1_4 from "../images/noodles/4/Artboard-noodle1-ml-4.jpg";
import a4_noodle1_5 from "../images/noodles/4/Artboard-noodle1-ml-5.jpg";

import a6_noodle1 from "../images/noodles/6/Artboard-noodle1-unionpay.jpg";

import a6_noodle1_1 from "../images/noodles/6/Artboard-noodle1-nu-1.jpg";
import a6_noodle1_2 from "../images/noodles/6/Artboard-noodle1-nu-2.jpg";
import a6_noodle1_3 from "../images/noodles/6/Artboard-noodle1-nu-3.jpg";
import a6_noodle1_4 from "../images/noodles/6/Artboard-noodle1-nu-4.jpg";
import a6_noodle1_5 from "../images/noodles/6/Artboard-noodle1-nu-5.jpg";
import a6_noodle1_6 from "../images/noodles/6/Artboard-noodle1-nu-6.jpg";
import a6_noodle1_7 from "../images/noodles/6/Artboard-noodle1-nu-7.jpg";
import a6_noodle1_8 from "../images/noodles/6/Artboard-noodle1-nu-8.jpg";
import a6_noodle1_9 from "../images/noodles/6/Artboard-noodle1-nu-9.jpg";
import a6_noodle1_10 from "../images/noodles/6/Artboard-noodle1-nu-10.jpg";
import a6_noodle1_11 from "../images/noodles/6/Artboard-noodle1-nu-11.jpg";
import a6_noodle1_12 from "../images/noodles/6/Artboard-noodle1-nu-12.jpg";
import a6_noodle1_13 from "../images/noodles/6/Artboard-noodle1-nu-13.jpg";
import a6_noodle1_14 from "../images/noodles/6/Artboard-noodle1-nu-14.jpg";

import a7_noodle1_1 from "../images/noodles/7/Artboard-noodle1-vip-1.jpg";
import a7_noodle1_2 from "../images/noodles/7/Artboard-noodle1-vip-2.jpg";
import a7_noodle1_3 from "../images/noodles/7/Artboard-noodle1-vip-3.jpg";
import a7_noodle1_4 from "../images/noodles/7/Artboard-noodle1-vip-4.jpg";
import a7_noodle1_5 from "../images/noodles/7/Artboard-noodle1-vip-5.jpg";
import a7_noodle1_6 from "../images/noodles/7/Artboard-noodle1-vip-6.jpg";
import a7_noodle1_7 from "../images/noodles/7/Artboard-noodle1-vip-7.jpg";
import a7_noodle1_8 from "../images/noodles/7/Artboard-noodle1-vip-8.jpg";
import a7_noodle1_9 from "../images/noodles/7/Artboard-noodle1-vip-9.jpg";
import a7_noodle1_10 from "../images/noodles/7/Artboard-noodle1-vip-10.jpg";
import a7_noodle1_11 from "../images/noodles/7/Artboard-noodle1-vip-11.jpg";
import a7_noodle1_12 from "../images/noodles/7/Artboard-noodle1-vip-12.jpg";
import a7_noodle1_13 from "../images/noodles/7/Artboard-noodle1-vip-13.jpg";
import a7_noodle1_14 from "../images/noodles/7/Artboard-noodle1-vip-14.jpg";
import a7_noodle1_15 from "../images/noodles/7/Artboard-noodle1-vip-15.jpg";
import a7_noodle1_16 from "../images/noodles/7/Artboard-noodle1-vip-16.jpg";
import a7_noodle1_17 from "../images/noodles/7/Artboard-noodle1-vip-17.jpg";

import a8_noodle1 from "../images/noodles/8/Artboard-noodle1.jpg";

import a8_noodle1_fr_1 from "../images/noodles/8/Artboard-noodle1-fr-1.jpg";
import a8_noodle1_fr_2 from "../images/noodles/8/Artboard-noodle1-fr-2.jpg";
import a8_noodle1_fr_3 from "../images/noodles/8/Artboard-noodle1-fr-3.jpg";
import a8_noodle1_fr_4 from "../images/noodles/8/Artboard-noodle1-fr-4.jpg";

import a8_noodle1_bc_1 from "../images/noodles/8/Artboard-noodle1-bc-1.jpg";
import a8_noodle1_bc_2 from "../images/noodles/8/Artboard-noodle1-bc-2.jpg";
import a8_noodle1_bc_3 from "../images/noodles/8/Artboard-noodle1-bc-3.jpg";
import a8_noodle1_bc_4 from "../images/noodles/8/Artboard-noodle1-bc-4.jpg";
import a8_noodle1_bc_5 from "../images/noodles/8/Artboard-noodle1-bc-5.jpg";
import a8_noodle1_bc_6 from "../images/noodles/8/Artboard-noodle1-bc-6.jpg";
import a8_noodle1_bc_7 from "../images/noodles/8/Artboard-noodle1-bc-7.jpg";

import a9_noodle1_ll_1 from "../images/noodles/9/Artboard-noodle1-ll-1.jpg";
import a9_noodle1_ll_2 from "../images/noodles/9/Artboard-noodle1-ll-2.jpg";
import a9_noodle1_ll_3 from "../images/noodles/9/Artboard-noodle1-ll-3.jpg";
import a9_noodle1_ll_4 from "../images/noodles/9/Artboard-noodle1-ll-4.jpg";
import a9_noodle1_ll_5 from "../images/noodles/9/Artboard-noodle1-ll-5.jpg";
import a9_noodle1_ll_6 from "../images/noodles/9/Artboard-noodle1-ll-6.jpg";
import a9_noodle1_ll_7 from "../images/noodles/9/Artboard-noodle1-ll-7.jpg";
import a9_noodle1_ll_8 from "../images/noodles/9/Artboard-noodle1-ll-8.jpg";

import a9_noodle1_lc_1 from "../images/noodles/9/Artboard-noodle1-lc-1.jpg";
import a9_noodle1_lc_2 from "../images/noodles/9/Artboard-noodle1-lc-2.jpg";
import a9_noodle1_lc_3 from "../images/noodles/9/Artboard-noodle1-lc-3.jpg";
import a9_noodle1_lc_4 from "../images/noodles/9/Artboard-noodle1-lc-4.jpg";
import a9_noodle1_lc_5 from "../images/noodles/9/Artboard-noodle1-lc-5.jpg";
import a9_noodle1_lc_6 from "../images/noodles/9/Artboard-noodle1-lc-6.jpg";
import a9_noodle1_lc_7 from "../images/noodles/9/Artboard-noodle1-lc-7.jpg";
import a9_noodle1_lc_8 from "../images/noodles/9/Artboard-noodle1-lc-8.jpg";
import a9_noodle1_lc_9 from "../images/noodles/9/Artboard-noodle1-lc-9.jpg";
import a9_noodle1_lc_10 from "../images/noodles/9/Artboard-noodle1-lc-10.jpg";

import a9_noodle1_ggs_1 from "../images/noodles/9/Artboard-noodle1-ggs-1.jpg";
import a9_noodle1_ggs_2 from "../images/noodles/9/Artboard-noodle1-ggs-2.jpg";
import a9_noodle1_ggs_3 from "../images/noodles/9/Artboard-noodle1-ggs-3.jpg";
import a9_noodle1_ggs_4 from "../images/noodles/9/Artboard-noodle1-ggs-4.jpg";
import a9_noodle1_ggs_5 from "../images/noodles/9/Artboard-noodle1-ggs-5.jpg";
import a9_noodle1_ggs_6 from "../images/noodles/9/Artboard-noodle1-ggs-6.jpg";
import a9_noodle1_ggs_7 from "../images/noodles/9/Artboard-noodle1-ggs-7.jpg";
import a9_noodle1_ggs_8 from "../images/noodles/9/Artboard-noodle1-ggs-8.jpg";

import noodle2_j4_mp4 from "../images/Artboard-noodle2-j4.mp4";
import noodle2_j4_jpg from "../images/Artboard-noodle2-j4.jpg";

import iconClose from "../images/account/close2.png";
import cookie from 'react-cookies';


export default connect(state => {
    return {
        session: state.session,
        ...state.sdkSettings,
    };
})(class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            url: "",
            index: 1,
            startTime: 0,
            endTime: 0,
            transactionId: '',
            userId: '',
            gameId: '',
            eventId: '',
            accessToken: '',
            nickname: '',
            createdAt: '',
            expireAt: '',
            expireAt_TTL: '',
            divStyle: '',

            showInfo: false,
            monthCount: 0,
            allcount: 0,
        };
        let { showInfo, monthCount, allcount, url, index, startTime, endTime, transactionId, eventId, userId, gameId, accessToken, nickname, createdAt, expireAt, expireAt_TTL } = formatQuery(this.props.location.search);
        if (parseInt(allcount) === 0) {
            allcount = 1;
        }
        this.state.showInfo = showInfo;
        console.log("showInfo =" + showInfo);
        this.state.monthCount = monthCount;
        this.state.allcount = allcount;
        this.state.url = url;

        this.state.index = index;
        console.log("index =" + index);
        this.state.startTime = startTime;
        this.state.endTime = endTime;
        this.state.transactionId = transactionId;

        this.state.userId = userId;
        this.state.gameId = gameId;
        this.state.accessToken = accessToken;
        this.state.nickname = nickname;
        this.state.createdAt = createdAt;
        this.state.expireAt = expireAt;
        this.state.expireAt_TTL = expireAt_TTL;
        if (eventId === undefined) eventId = "";
        this.state.eventId = eventId;
        // 保存弹出时间
        if (!cookie.load("entr" + accessToken + "index" + index)) {
            cookie.save("entr" + accessToken + "index" + index, "entr" + accessToken + "index" + index, {
                path: "/",
                expires: new Date(new Date().getTime() + 0.5 * 3600 * 1000)
            });
        }
    }

    async componentDidMount() {
        // if (this.state.showInfo === "true"){
        //     console.log("更新累登数据");
        //     const data = await this.userSignIn(this.state.accessToken);
        //     console.log(data);
        //     this.setState({
        //         monthCount: data.monthCount,
        //         allcount: data.allcount
        //     });
        // }
    }

    async userSignIn(accessToken) {
        const res = await Lambda.post(getFullPath(`profile/userSignIn`), null, accessToken);
        if (res.status === 200) {
            return res.data;
        } else {
            return "";
        }
    }

    async closeAd() {
        if (this.state.showInfo === "true") {
            console.log("更新累登数据");
            const data = await this.userSignIn(this.state.accessToken);
            console.log(data);
            this.setState({
                monthCount: data.monthCount,
                allcount: data.allcount
            });
        }
    }

    render() {
        let showCaption = "狠心離開";
        if (this.state.showInfo === "true") {
            showCaption = "簽到及狠心離開";
        }
        let isIOS = "1";
        if (typeof window !== `undefined`) {
            isIOS = "0";
            if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
                // 暂时去掉ios两板功能
                isIOS = "1";
            }
        }
        let noodle = noodle1;
        let loginnoodle = login01_p;
        switch (parseInt(this.state.monthCount)) {
            case 2:
                loginnoodle = login02_p;
                break;
            case 3:
                loginnoodle = login03_p;
                break;
            case 4:
                loginnoodle = login04_p;
                break;
            case 5:
                loginnoodle = login05_p;
                break;
            case 6:
                loginnoodle = login06_p;
                break;
            case 7:
                loginnoodle = login07_p;
                break;
            case 8:
                loginnoodle = login08_p;
                break;
            case 9:
                loginnoodle = login09_p;
                break;
            case 10:
                loginnoodle = login10_p;
                break;
            case 11:
                loginnoodle = login11_p;
                break;
            case 12:
                loginnoodle = login12_p;
                break;
            case 13:
                loginnoodle = login13_p;
                break;
            case 14:
                loginnoodle = login14_p;
                break;
            case 15:
                loginnoodle = login15_p;
                break;
            case 16:
                loginnoodle = login16_p;
                break;
            case 17:
                loginnoodle = login17_p;
                break;
            case 18:
                loginnoodle = login18_p;
                break;
            case 19:
                loginnoodle = login19_p;
                break;
            case 20:
                loginnoodle = login20_p;
                break;
            case 21:
                loginnoodle = login21_p;
                break;
            case 22:
                loginnoodle = login22_p;
                break;
            case 23:
                loginnoodle = login23_p;
                break;
            case 24:
                loginnoodle = login24_p;
                break;
            case 25:
                loginnoodle = login25_p;
                break;
            case 26:
                loginnoodle = login26_p;
                break;
            case 27:
                loginnoodle = login27_p;
                break;
            case 28:
                loginnoodle = login28_p;
                break;
            case 29:
                loginnoodle = login29_p;
                break;
            case 30:
                loginnoodle = login30_p;
                break;
            case 31:
                loginnoodle = login31_p;
                break;
        }

        const mode = detectOrient();
        let width = "80vw";
        let height = "98vw";
        let bottom = "-11%";
        if (mode === "LANDSCAPE") {
            noodle = noodle2;

            width = "120vh";
            height = "38vh";
            bottom = "-29%";

            loginnoodle = login01_l;
            switch (parseInt(this.state.monthCount)) {
                case 2:
                    loginnoodle = login02_l;
                    break;
                case 3:
                    loginnoodle = login03_l;
                    break;
                case 4:
                    loginnoodle = login04_l;
                    break;
                case 5:
                    loginnoodle = login05_l;
                    break;
                case 6:
                    loginnoodle = login06_l;
                    break;
                case 7:
                    loginnoodle = login07_l;
                    break;
                case 8:
                    loginnoodle = login08_l;
                    break;
                case 9:
                    loginnoodle = login09_l;
                    break;
                case 10:
                    loginnoodle = login10_l;
                    break;
                case 11:
                    loginnoodle = login11_l;
                    break;
                case 12:
                    loginnoodle = login12_l;
                    break;
                case 13:
                    loginnoodle = login13_l;
                    break;
                case 14:
                    loginnoodle = login14_l;
                    break;
                case 15:
                    loginnoodle = login15_l;
                    break;
                case 16:
                    loginnoodle = login16_l;
                    break;
                case 17:
                    loginnoodle = login17_l;
                    break;
                case 18:
                    loginnoodle = login18_l;
                    break;
                case 19:
                    loginnoodle = login19_l;
                    break;
                case 20:
                    loginnoodle = login20_l;
                    break;
                case 21:
                    loginnoodle = login21_l;
                    break;
                case 22:
                    loginnoodle = login22_l;
                    break;
                case 23:
                    loginnoodle = login23_l;
                    break;
                case 24:
                    loginnoodle = login24_l;
                    break;
                case 25:
                    loginnoodle = login25_l;
                    break;
                case 26:
                    loginnoodle = login26_l;
                    break;
                case 27:
                    loginnoodle = login27_l;
                    break;
                case 28:
                    loginnoodle = login28_l;
                    break;
                case 29:
                    loginnoodle = login29_l;
                    break;
                case 30:
                    loginnoodle = login30_l;
                    break;
                case 31:
                    loginnoodle = login31_l;
                    break;
            }
        }
        console.log(this.state.url);
        noodle = getNoodleImage(mode, this.state.url, this.state.index, noodle, isIOS);
        console.log("到这了");
        return (
            <Grid textAlign='center' style={{
                width: '100vw',
                height: "100vh",
                background: "transparent",
                padding: "3vh"
            }} verticalAlign='middle'>
                <Grid.Column style={{
                    width: '100vw',
                    height: "100vh"
                }}>
                    <Modal
                        style={{ width: `${width}`, height: `${height}`, borderRadius: '10px 10px 10px 10px' }}
                        open
                    >
                        <div style={{ width: `${width}` }} >
                            <div style={{
                                height: '37px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold',
                                backgroundColor: "#57C532",
                                margin: "-78px 0 0",
                                paddingTop: '8px', border: '1px solid #D6D6D6',
                                borderRadius: '10px 10px 0px 0px',
                                opacity: '1'
                            }}
                            >
                                <div style={{
                                    position: "absolute",
                                    left: "0.5rem"
                                }} onClick={async () => {
                                    await this.closeAd();
                                    // await showFJB(this.state.accessToken, this.state.gameId, this.state.userId, this.state.nickname, this.state.createdAt, this.state.expireAt, this.state.expireAt_TTL);
                                    // console.log({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                                    // 去掉飞机杯，加回来
                                    AndroidComm.sendSession({ gameId: this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken, nickname: this.state.nickname, createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
                                }}>
                                    <Image style={{
                                        height: "2.5vh",
                                        minHeight: "16px"
                                    }} centered
                                        src={iconClose}
                                    />
                                </div>
                                <Timer endTime={this.state.endTime} />
                            </div>

                            <div style={{ height: "100%" }}
                            >
                                {(mode === "LANDSCAPE") && (
                                    <div style={{ height: "60vh" }}>
                                        <div style={{ float: "left" }} onClick={async () => {
                                            await this.closeAd();
                                            let platform = cookie.load("_platform");
                                            let url = `/confirm-purchase/${this.props.location.search}`;
                                            if (this.props.location.search.indexOf("platform") === -1) {
                                                url += `&platform=${platform}`;
                                            }
                                            window.location.href = url;
                                        }}>
                                            {
                                                ((this.state.url !== "sdk-ad-noodle-j4")
                                                    || (isIOS === "1"))
                                                && (<Image src={noodle} style={{ width: '60vh', height: '60vh', objectFit: 'fill' }} />)
                                            }
                                            {
                                                ((this.state.url === "sdk-ad-noodle-j4")
                                                    && (isIOS === "0"))
                                                && (<ImgVideo src={noodle} style={{ width: '60vh', height: '60vh', objectFit: 'fill' }} />)
                                            }

                                        </div>
                                        <div style={{ float: "right" }}>
                                            <Image src={loginnoodle} style={{ width: '60vh', height: '60vh', objectFit: 'fill' }} />
                                        </div>
                                        <div style={{
                                            position: "absolute",
                                            bottom: `${bottom}`,
                                            right: "2%",
                                            color: "#fff",
                                            fontSize: "12px",
                                            fontStyle: "bold",
                                            width: "30px",
                                            textAlign: "center",
                                            textShadow: "1px 1px #000,-1px -1px #000,1px -1px #000,-1px 1px #000"
                                        }}>{this.state.allcount}</div>
                                    </div>
                                )}
                                {(mode === "PORTRAIT") && (
                                    <div>
                                        <div onClick={async () => {
                                            await this.closeAd();
                                            let platform = cookie.load("_platform");
                                            let url = `/confirm-purchase/${this.props.location.search}`;
                                            if (this.props.location.search.indexOf("platform") === -1) {
                                                url += `&platform=${platform}`;
                                            }
                                            window.location.href = url;
                                        }}>
                                            {
                                                ((this.state.url !== "sdk-ad-noodle-j4")
                                                    || (isIOS === "1"))
                                                && (<Image src={noodle} style={{ width: '80vw', height: '80vw', objectFit: 'fill' }} />)
                                            }
                                            {
                                                ((this.state.url === "sdk-ad-noodle-j4")
                                                    && (isIOS === "0"))
                                                && (<ImgVideo src={noodle} style={{ width: '80vw', height: '80vw', objectFit: 'fill' }} />)
                                            }

                                        </div>
                                        <div >
                                            <Image src={loginnoodle} style={{ width: '80vw', height: '40vw', objectFit: 'fill' }} />
                                        </div>
                                        <div style={{
                                            position: "absolute",
                                            bottom: `${bottom}`,
                                            right: "6%",
                                            color: "#fff",
                                            fontSize: "12px",
                                            fontStyle: "bold",
                                            width: "30px",
                                            textAlign: "center",
                                            textShadow: "1px 1px #000,-1px -1px #000,1px -1px #000,-1px 1px #000"
                                        }}>{this.state.allcount}</div>
                                    </div>
                                )}


                            </div>

                            <div style={{
                                height: '41px', textAlign: 'center', fontSize: '1.35em', fontWeight: 'bold',
                                backgroundColor: "#57C532",
                                paddingTop: '10px', border: '1px solid #D6D6D6',
                                borderRadius: '0px 0px 10px 10px',
                                opacity: '1'
                            }}
                                onClick={async () => {
                                    await this.closeAd();
                                    // await showFJB(this.state.accessToken, this.state.gameId, this.state.userId, this.state.nickname, this.state.createdAt, this.state.expireAt, this.state.expireAt_TTL);
                                    // console.log({ gameId:this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken,nickname: this.state.nickname,createdAt: this.state.createdAt, expireAt: this.state.expireAt });
                                    // 去掉飞机杯，加回来
                                    AndroidComm.sendSession({ gameId: this.state.gameId, userId: this.state.userId, accessToken: this.state.accessToken, nickname: this.state.nickname, createdAt: this.state.createdAt, expireAt: this.state.expireAt, expireAt_TTL: this.state.expireAt_TTL });
                                }}
                            >
                                <span style={{ color: '#FFF' }}>
                                    {showCaption}
                                </span>
                            </div>
                        </div>

                    </Modal>
                </Grid.Column>
            </Grid>);
    }

});

export async function showFJB(accessToken, gameId, userId, nickname, createdAt, expireAt, expireAt_TTL) {
    const res = await KaisakuApi.getInfoForM1(accessToken);
    if (res.success === true) {
        if ((!res.message) && (res.item.status === "SALE")) {
            navigate(`/xr-saleing-page1/?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&m1=${res.item.maxCount}&m2=${res.item.saleCount}`);
        } else {
            navigate(`/xr-saleing-page4/?gameId=${gameId}&accessToken=${accessToken}&userId=${userId}&nickname=${nickname}&createdAt=${createdAt}&expireAt=${expireAt}&expireAt_TTL=${expireAt_TTL}&ids=${res.ids}&message=${res.message}`);
        }
    } else {
        AndroidComm.sendSession({ gameId: gameId, userId: userId, accessToken: accessToken, nickname: nickname, createdAt: createdAt, expireAt: expireAt, expireAt_TTL: expireAt_TTL });
    }
}


function getNoodleImage(mode, url, index, noodle, isIOS) {
    console.log(url);
    let newnoodle = noodle;
    if (url === "sdk-ad-noodle-nw") {
        switch (parseInt(index)) {
            case 1:
                newnoodle = a3_noodle1;
                break;
            case 2:
                newnoodle = a3_noodle1_2;
                break;
            case 3:
                newnoodle = a3_noodle1_3;
                break;
            case 4:
                newnoodle = a3_noodle1_4;
                break;
            case 5:
                newnoodle = a3_noodle1_5;
                break;
            case 6:
                newnoodle = a3_noodle1_6;
                break;
            case 7:
                newnoodle = a3_noodle1_7;
                break;
        }
    } else if (url === "sdk-ad-noodle-ml") {
        switch (parseInt(index)) {
            case 1:
                newnoodle = a4_noodle1;
                break;
            case 2:
                newnoodle = a4_noodle1_2;
                break;
            case 3:
                newnoodle = a4_noodle1_3;
                break;
            case 4:
                newnoodle = a4_noodle1_4;
                break;
            case 5:
                newnoodle = a4_noodle1_5;
                break;
        }
    } else if (url === "sdk-ad-noodle-j4") {
        newnoodle = noodle2_j4_mp4;
        if (isIOS === "1") {
            newnoodle = noodle2_j4_jpg;
        }
    } else if (url === "sdk-ad-noodle-unionpay") {
        newnoodle = a6_noodle1;
    } else if (url === "sdk-ad-noodle-nu") {
        switch (parseInt(index)) {
            case 1:
                newnoodle = a6_noodle1_1;
                break;
            case 2:
                newnoodle = a6_noodle1_2;
                break;
            case 3:
                newnoodle = a6_noodle1_3;
                break;
            case 4:
                newnoodle = a6_noodle1_4;
                break;
            case 5:
                newnoodle = a6_noodle1_5;
                break;
            case 6:
                newnoodle = a6_noodle1_6;
                break;
            case 7:
                newnoodle = a6_noodle1_7;
                break;
            case 8:
                newnoodle = a6_noodle1_8;
                break;
            case 9:
                newnoodle = a6_noodle1_9;
                break;
            case 10:
                newnoodle = a6_noodle1_10;
                break;
            case 11:
                newnoodle = a6_noodle1_11;
                break;
            case 12:
                newnoodle = a6_noodle1_12;
                break;
            case 13:
                newnoodle = a6_noodle1_13;
                break;
            case 14:
                newnoodle = a6_noodle1_14;
                break;
        }
    } else if (url === "sdk-ad-noodle-vip") {
        switch (parseInt(index)) {
            case 1:
                newnoodle = a7_noodle1_1;
                break;
            case 2:
                newnoodle = a7_noodle1_2;
                break;
            case 3:
                newnoodle = a7_noodle1_3;
                break;
            case 4:
                newnoodle = a7_noodle1_4;
                break;
            case 5:
                newnoodle = a7_noodle1_5;
                break;
            case 6:
                newnoodle = a7_noodle1_6;
                break;
            case 7:
                newnoodle = a7_noodle1_7;
                break;
            case 8:
                newnoodle = a7_noodle1_8;
                break;
            case 9:
                newnoodle = a7_noodle1_9;
                break;
            case 10:
                newnoodle = a7_noodle1_10;
                break;
            case 11:
                newnoodle = a7_noodle1_11;
                break;
            case 12:
                newnoodle = a7_noodle1_12;
                break;
            case 13:
                newnoodle = a7_noodle1_13;
                break;
            case 14:
                newnoodle = a7_noodle1_14;
                break;
            case 15:
                newnoodle = a7_noodle1_15;
                break;
            case 16:
                newnoodle = a7_noodle1_16;
                break;
            case 17:
                newnoodle = a7_noodle1_17;
                break;
        }
    } else if (url === "sdk-ad-noodle-fr") {
        switch (parseInt(index)) {
            case 1:
                newnoodle = a8_noodle1_fr_1;
                break;
            case 2:
                newnoodle = a8_noodle1_fr_2;
                break;
            case 3:
                newnoodle = a8_noodle1_fr_3;
                break;
            case 4:
                newnoodle = a8_noodle1_fr_4;
                break;
        }
    } else if (url === "sdk-ad-noodle-bc") {
        switch (parseInt(index)) {
            case 1:
                newnoodle = a8_noodle1_bc_1;
                break;
            case 2:
                newnoodle = a8_noodle1_bc_2;
                break;
            case 3:
                newnoodle = a8_noodle1_bc_3;
                break;
            case 4:
                newnoodle = a8_noodle1_bc_4;
                break;
            case 5:
                newnoodle = a8_noodle1_bc_5;
                break;
            case 6:
                newnoodle = a8_noodle1_bc_6;
                break;
            case 7:
                newnoodle = a8_noodle1_bc_7;
                break;
        }
    } else if (url === "sdk-ad-noodle") {
        newnoodle = a8_noodle1;
    } else if (url === "sdk-ad-noodle-ll") {
        switch (parseInt(index)) {
            case 1:
                newnoodle = a9_noodle1_ll_1;
                break;
            case 2:
                newnoodle = a9_noodle1_ll_2;
                break;
            case 3:
                newnoodle = a9_noodle1_ll_3;
                break;
            case 4:
                newnoodle = a9_noodle1_ll_4;
                break;
            case 5:
                newnoodle = a9_noodle1_ll_5;
                break;
            case 6:
                newnoodle = a9_noodle1_ll_6;
                break;
            case 7:
                newnoodle = a9_noodle1_ll_7;
                break;
            case 8:
                newnoodle = a9_noodle1_ll_8;
                break;
        }
    } else if (url === "sdk-ad-noodle-lc") {
        switch (parseInt(index)) {
            case 1:
                newnoodle = a9_noodle1_lc_1;
                break;
            case 2:
                newnoodle = a9_noodle1_lc_2;
                break;
            case 3:
                newnoodle = a9_noodle1_lc_3;
                break;
            case 4:
                newnoodle = a9_noodle1_lc_4;
                break;
            case 5:
                newnoodle = a9_noodle1_lc_5;
                break;
            case 6:
                newnoodle = a9_noodle1_lc_6;
                break;
            case 7:
                newnoodle = a9_noodle1_lc_7;
                break;
            case 8:
                newnoodle = a9_noodle1_lc_8;
                break;
            case 9:
                newnoodle = a9_noodle1_lc_9;
                break;
            case 10:
                newnoodle = a9_noodle1_lc_10;
                break;
        }
    } else if (url === "sdk-ad-noodle-ggs") {
        switch (parseInt(index)) {
            case 1:
                newnoodle = a9_noodle1_ggs_1;
                break;
            case 2:
                newnoodle = a9_noodle1_ggs_2;
                break;
            case 3:
                newnoodle = a9_noodle1_ggs_3;
                break;
            case 4:
                newnoodle = a9_noodle1_ggs_4;
                break;
            case 5:
                newnoodle = a9_noodle1_ggs_5;
                break;
            case 6:
                newnoodle = a9_noodle1_ggs_6;
                break;
            case 7:
                newnoodle = a9_noodle1_ggs_7;
                break;
            case 8:
                newnoodle = a9_noodle1_ggs_8;
                break;
        }
    }
    return newnoodle;
}

function getBjTime() {
    let d = new Date();
    let local = d.getTime();
    let offset = d.getTimezoneOffset() * 60000;
    let localUtc = new Date().getTimezoneOffset() / 60;
    let utcTime;
    if (localUtc > 0) {
        utcTime = local - offset;
    } else {
        utcTime = local + offset;
    }
    let localTime = utcTime + 3600000 * Math.abs(8);
    return localTime;
}

function showHint(seconds) {
    const seconds1 = 1000;
    const minutes = seconds1 * 60;
    const hour = minutes * 60;
    const day = hour * 24;
    let iDay = parseInt(seconds / day);
    let d = seconds - iDay * day;
    let iHour = parseInt(d / hour);
    d = seconds - iDay * day - iHour * hour;
    let iMinutes = parseInt(d / minutes);
    d = seconds - iDay * day - iHour * hour - iMinutes * minutes;
    let iseconds = parseInt(d / seconds1);
    let showTime1 = "優惠時間尚餘: ";
    if (iDay > 0) {
        showTime1 = showTime1 + iDay + "天";
    }
    if (iHour > 0) {
        showTime1 = showTime1 + iHour + "小時";
    }
    if (iMinutes > 0) {
        showTime1 = showTime1 + iMinutes + "分";
    }
    if (iseconds > 0) {
        showTime1 = showTime1 + iseconds + "秒";
    }
    if (seconds <= 1000) {
        showTime1 = "已過期";
    }
    let color = "#ffffff";
    if (seconds < 60000) {
        color = "#ff0000";
    }
    return "<span style='color:" + color + "'>" + showTime1 + "</span>";
}

class Timer extends Component {
    state = {
        seconds: 0,
        showTime: ''
    };

    tick = () => {
        const { seconds } = this.state;
        let showTime1 = showHint(seconds);

        this.setState({
            seconds: seconds - 1000,
            showTime: showTime1
        })
    }

    componentDidMount() {
        // 定时器，可以修改1000为自己想要的时间，
        const { endTime } = this.props;
        this.state.seconds = endTime - getBjTime();

        this.interval = setInterval(() => this.tick(), 1000);
    }

    componentWillUnmount() {
        // 清除定时器
        clearInterval(this.interval);
        this.setState = () => false;
    }
    render() {
        const { endTime } = this.props;
        this.state.seconds = endTime - getBjTime();
        this.state.showTime = showHint(this.state.seconds);

        return (
            <div style={{ fontSize: '1.25em' }} dangerouslySetInnerHTML={{ __html: this.state.showTime }}></div>
        )
    }
}


function detectOrient() {
    if (typeof window !== `undefined`) {
        // let isIOS = "0";
        // if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {  //判断iPhone|iPad|iPod|iOS
        //     // 暂时去掉ios两板功能
        //     isIOS = "1";
        // }
        // if (isIOS === "0") return "PORTRAIT";
        if (window.innerHeight >= window.innerWidth) {
            // 竖屏
            // console.log("竖屏");
            return "PORTRAIT";
        } else {
            // 横屏
            // console.log("横屏");
            return "LANDSCAPE";
        }
    } else {
        return "PORTRAIT";
    }
}